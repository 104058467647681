define("discourse/plugins/discourse-anonymous-post/discourse/initializers/anonymous-post", ["exports", "discourse/lib/plugin-api", "discourse/models/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    if (!Discourse.SiteSettings.anonymous_post_enabled) return;
    _composer.default.serializeOnCreate("is_anonymous_post");
    api.includePostAttributes("is_anonymous_post");
    api.reopenWidget("post", {
      buildClasses(attrs) {
        const classes = this._super(...arguments);
        if (attrs.is_anonymous_post) {
          classes.push("anonymous-post");
        }
        return classes;
      }
    });
  }
  var _default = _exports.default = {
    name: "anonymous-post",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});