define("discourse/plugins/discourse-anonymous-post/discourse/connectors/composer-fields-below/anonymous-post-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const composer = Discourse.__container__.lookup("controller:composer");
      component.setProperties({
        composer
      });
    },
    actions: {
      anonymousReply() {
        const setVal = t => {
          this.composer.model.set("is_anonymous_post", t);
        };
        setVal(1);
        const isSaved = this.composer.save(true);
        if (isSaved) {
          isSaved.catch(_e => {
            setVal(null);
          });
        } else {
          setVal(null);
        }
      }
    }
  };
});